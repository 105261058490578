html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0
}

main {
  display: block
}

h1 {
  font-size: 2em
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

pre {
  font-family: monospace, monospace;
  font-size: 1em
}

a {
  background-color: transparent
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted
}

b,
strong {
  font-weight: bolder
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em
}

small {
  font-size: 80%
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

img {
  border-style: none
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0
}

button,
input {
  overflow: visible
}

button,
select {
  text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText
}

fieldset {
  padding: .35em .75em .625em
}

legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal
}

progress {
  vertical-align: baseline
}

textarea {
  overflow: auto
}

[type=checkbox],
[type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

details {
  display: block
}

summary {
  display: list-item
}

template {
  display: none
}

[hidden] {
  display: none
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

html {
  scroll-behavior: smooth
}

::-webkit-input-placeholder {
  color: rgba(26, 26, 26, .4)
}

::-moz-placeholder {
  color: rgba(26, 26, 26, .4)
}

:-ms-input-placeholder {
  color: rgba(26, 26, 26, .4)
}

::-ms-input-placeholder {
  color: rgba(26, 26, 26, .4)
}

::placeholder {
  color: rgba(26, 26, 26, .4)
}

ul {
  list-style: none
}

a {
  text-decoration: none
}

strong {
  font-weight: 700
}

body {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #687086;
  font-weight: 400;
  position: relative;
  background-color: #2058fa
}

body::before {
  background: url(../img/main-bg.png) no-repeat;
  background-size: cover;
  content: ' ';
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  will-change: transform;
  z-index: -1
}

.bg {
  background: url(../img/circle.png) no-repeat calc(50% + 30px) 10px;
  background-size: 1920px auto;
  position: relative;
  overflow-x: hidden
}

.bg::after {
  content: '';
  position: absolute;
  top: 48%;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  left: calc(50% + 730px);
  width: 345px;
  height: 275px;
  background: url(../img/circles.svg) no-repeat right top
}

.container {
  max-width: 1650px;
  margin: 0 auto;
  padding: 0 10px
}

.logo {
  width: 277px
}

.logo img {
  width: 277px;
  display: block;
  margin: 0 auto
}

.h1 {
  font-family: Hind, Arial, sans-serif;
  font-weight: 700;
  font-size: 72px;
  line-height: 80px;
  color: #fff
}

.h2 {
  font-family: Hind, Arial, sans-serif;
  font-weight: 700;
  font-size: 56px;
  line-height: 64px
}

.h3 {
  font-family: Hind, Arial, sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: normal
}

.h3 {
  font-family: Hind, Arial, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px
}

.icon {
  font-size: 16px;
  color: #fff;
  line-height: 16px;
  font-family: Hind, Arial, sans-serif
}

.uppercase {
  font-family: Raleway, Arial, sans-serif;
  font-weight: 800;
  font-size: 18px;
  line-height: 13px;
  text-transform: uppercase
}

.marked {
  color: #18daab
}

.button {
  background: -o-linear-gradient(357.13deg, #eda130 0, #ffc107 100%);
  background: linear-gradient(92.87deg, #eda130 0, #ffc107 100%);
  -webkit-box-shadow: 0 2px 0 #c7821a, 0 24px 50px rgba(237, 161, 48, .44);
  box-shadow: 0 2px 0 #c7821a, 0 24px 50px rgba(237, 161, 48, .44);
  border-radius: 100px;
  font-weight: 800;
  font-size: 18px;
  line-height: 21px;
  font-family: Raleway, Arial, sans-serif;
  padding: 18px 48px 17px 24px;
  color: rgba(0, 0, 0, .6);
  cursor: pointer;
  border: none;
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative
}

.button:hover {
  opacity: .9
}

.button::after {
  content: '';
  position: absolute;
  right: 56px;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #c0900b
}

.button--pen {
  background: url(../img/pen.svg) no-repeat calc(100% - 18px) center, -o-linear-gradient(357.13deg, #eda130 0, #ffc107 100%);
  background: url(../img/pen.svg) no-repeat calc(100% - 18px) center, linear-gradient(92.87deg, #eda130 0, #ffc107 100%);
  padding-right: 82px
}

.button--sign-up {
  background: url(../img/sign-up.svg) no-repeat calc(100% - 18px) center, -o-linear-gradient(357.13deg, #eda130 0, #ffc107 100%);
  background: url(../img/sign-up.svg) no-repeat calc(100% - 18px) center, linear-gradient(92.87deg, #eda130 0, #ffc107 100%);
  padding-left: 40px
}

.label {
  font-size: 12px;
  line-height: 15px;
  font-family: Inter, Arial, sans-serif;
  color: #16113d;
  margin-bottom: 6px
}

.required {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #e31a3e
}

.input {
  border: 1px solid #949494;
  color: #1a1a1a;
  font-size: 14px;
  line-height: 17px;
  font-family: Inter, Arial, sans-serif;
  min-height: 40px;
  padding: 10px 12px;
  outline: 0;
  border-radius: 100px;
  background-color: #fff;
  width: 100%;
}

.input:focus {
  border-color: #8e85cd
}

.select {
  border: 1px solid #949494;
  color: #1a1a1a;
  font-size: 14px;
  line-height: 17px;
  font-family: Inter, Arial, sans-serif;
  min-height: 40px;
  padding: 10px 12px;
  outline: 0;
  border-radius: 100px;
  background-color: #fff;
  width: 100%;
  appearance: none;
  cursor: pointer;
}

.submit {
  color: #fff;
  font-family: Hind, Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  width: 221px;
  height: 40px;
  background: -webkit-gradient(linear, left bottom, left top, from(#2058fa), to(#2058fa));
  background: -o-linear-gradient(bottom, #2058fa, #2058fa);
  background: linear-gradient(0deg, #2058fa, #2058fa);
  -webkit-box-shadow: 0 12px 20px rgba(32, 88, 250, .2);
  box-shadow: 0 12px 20px rgba(32, 88, 250, .2);
  border-radius: 100px;
  padding: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.submit::after {
  display: none
}

.header {
  padding-top: 34px;
  margin-bottom: 155px
}

.header-top {
  display: grid;
  grid-template-columns: 277px 1fr 277px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.header .logo {
  width: 277px
}

.header-nav {
  justify-self: center
}

.header-nav__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.header-nav__item {
  margin-right: 50px
}

.header-nav__link {
  color: #fff;
  display: block;
  position: relative;
  font-size: 24px;
  line-height: 150%;
  font-weight: 400
}

.header-nav__link:hover::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
  height: 6px;
  border-radius: 10px;
  background-color: #ffc807
}

.preview {
  padding-bottom: 101px
}

.preview .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.preview .h1 {
  padding-left: 36px;
  position: relative;
  margin-bottom: 40px
}

.preview .h1::after {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  height: 90%;
  width: 6px;
  border-radius: 3px;
  background-color: #ffc807
}

.preview__text {
  width: 864px
}

.preview__list {
  margin-bottom: 40px;
  width: 640px;
  display: grid;
  gap: 21px 0;
  grid-template-columns: 1fr 1fr
}

.preview .button--pen {
  margin-bottom: 45px
}

.preview__item {
  color: #fff;
  font-weight: 600;
  padding-left: 30px;
  background: url(../img/check.svg) no-repeat center left;
  background-size: 20px
}

.preview__advatages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.preview__advantage {
  font-weight: 600;
  color: #fff;
  background-image: url(../img/20+.png);
  background-repeat: no-repeat;
  background-position: center;
  padding: 55px 15px 16px 22px;
  min-height: 120px
}

.preview__advantage:first-child {
  width: 303px;
  margin-right: 35px;
  background-size: 296px auto
}

.preview__advantage:last-child {
  width: 220px;
  background-image: url(../img/99.png);
  background-size: 220px auto
}

.preview .form {
  width: 390px;
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#edeff7));
  background: -o-linear-gradient(top, #fff 0, #edeff7 100%);
  background: linear-gradient(180deg, #fff 0, #edeff7 100%);
  -webkit-box-shadow: 0 4px 46px rgba(0, 0, 0, .25);
  box-shadow: 0 4px 46px rgba(0, 0, 0, .25);
  border-radius: 20px;
  padding: 30px 24px 26px 24px;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-transform: translate(-100px, 30px);
  -ms-transform: translate(-100px, 30px);
  transform: translate(-100px, 30px)
}

.preview .form-title {
  font-weight: 700;
  font-size: 24px;
  line-height: normal;
  font-family: Hind, Arial, sans-serif;
  color: #364572;
  position: relative;
  margin-bottom: 24px;
  padding-bottom: 13px;
  text-align: center
}

.preview .form-title::after {
  content: '';
  position: absolute;
  background-color: #ffc807;
  border-radius: 3px;
  height: 6px;
  width: 72px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%)
}

.preview .form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto 16px auto
}

.preview .form-row--flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.preview .form-row__item--small {
  max-width: 100px;
  width: 100%;
  position: relative;
}

.form-row__item--small:before {
  content: '';
  position: absolute;
  bottom: 15px;
  right: 15px;
  height: 9px;
  width: 16px;
  background-image: url(../img/select-arrow.png);
  background-size: contain;
  pointer-events: none;
}

.preview .form-row__item--big {
  /* width: 70%; */
}

.preview .form-footer {
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.services .container {
  background: #fff;
  -webkit-box-shadow: 0 30px 60px rgba(0, 0, 0, .1);
  box-shadow: 0 30px 60px rgba(0, 0, 0, .1);
  border-radius: 24px;
  padding: 60px
}

.services .h2 {
  text-align: center;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 66px
}

.services .h2::after {
  content: '';
  position: absolute;
  background-color: #ffc807;
  border-radius: 3px;
  height: 6px;
  width: 72px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%)
}

.services__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px
}

.services__item {
  background: #fff;
  border: 1px solid rgba(54, 69, 114, .2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 30px 50px rgba(54, 69, 114, .08);
  box-shadow: 0 30px 50px rgba(54, 69, 114, .08);
  border-radius: 12px;
  padding: 30px;
  min-height: 308px
}

.services__item .h3 {
  padding-left: 100px;
  background-repeat: no-repeat;
  background-position: left center;
  min-height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px
}

.services__item-point {
  margin-bottom: 14px;
  padding-left: 32px;
  background-repeat: no-repeat;
  background-position: left center
}

.services .services__item-point:first-of-type {
  background-image: url(../img/app-store.svg)
}

.services .services__item-point:nth-of-type(2) {
  background-image: url(../img/games.svg)
}

.services .services__item-point:nth-of-type(3) {
  background-image: url(../img/ux.svg)
}

.services .services__item-point:nth-of-type(4) {
  background-image: url(../img/tablet.svg)
}

.services__item:first-child .h3 {
  background-image: url(../img/iphone.svg)
}

.services__item:nth-child(2) .h3 {
  background-image: url(../img/ipad.svg)
}

.services__item:nth-child(3) .h3 {
  background-image: url(../img/android.svg)
}

.services__item:last-child {
  background-color: #2058fa
}

.services__item:last-child .h3 {
  color: #fff;
  background-image: url(../img/idea.svg)
}

.services__item:last-child p {
  color: #fff;
  margin-bottom: 30px
}

.development {
  margin-top: -20px;
  margin-bottom: 54px;
  background: url(../img/development.png) no-repeat top center;
  background-size: 1735px auto
}

.development .h2 {
  color: #fff;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 60px;
  padding-top: 100px;
  text-transform: capitalize
}

.development .h2::after {
  content: '';
  position: absolute;
  background-color: #ffc807;
  border-radius: 3px;
  height: 6px;
  width: 72px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%)
}

.development .h2::after {
  left: 0;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none
}

.development .wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.development__left {
  width: 670px
}

.development__left p {
  color: #fff;
  margin-bottom: 20px
}

.development__left .button {
  margin-top: 40px
}

.development__list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px 58px
}

.development__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.development__item img {
  margin-bottom: 15px
}

.process {
  margin-bottom: 41px;
  background: url(../img/process.png) no-repeat center top;
  position: relative
}

.process::after {
  content: '';
  position: absolute;
  left: calc(50% - 950px);
  top: 230px;
  background-color: #ffc807;
  width: 373px;
  height: 373px;
  border-radius: 50%;
  z-index: -1
}

.process::before {
  content: '';
  position: absolute;
  left: calc(50% + 750px);
  top: 1004px;
  background-color: #ffc807;
  width: 119px;
  height: 119px;
  border-radius: 50%;
  z-index: -1
}

.process .h2 {
  padding-top: 100px;
  text-align: center;
  color: #fff;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 30px;
  text-transform: capitalize
}

.process .h2::after {
  content: '';
  position: absolute;
  background-color: #ffc807;
  border-radius: 3px;
  height: 6px;
  width: 72px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%)
}

.process__tabs {
  width: 730px;
  margin: 0 auto;
  border: 1px solid #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  min-height: 66px;
  justify-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 85px
}

.process .active {
  color: rgba(0, 0, 0, .6);
  background: -o-linear-gradient(357.13deg, #eda130 0, #ffc107 100%);
  background: linear-gradient(92.87deg, #eda130 0, #ffc107 100%);
  padding: 21px 49px;
  border-radius: 100px
}

.process__tab {
  color: #fff
}

.process__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px
}

.process__item {
  background: #fff;
  -webkit-box-shadow: 0 20px 50px rgba(17, 29, 66, .3);
  box-shadow: 0 20px 50px rgba(17, 29, 66, .3);
  border-radius: 12px;
  min-height: 343px;
  text-align: center;
  padding: 0 20px;
  background-repeat: no-repeat;
  background-position: center 30px
}

.process__item .h3 {
  padding-top: 130px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  position: relative
}

.process__item .h3::after {
  content: '';
  position: absolute;
  background-color: #ffc807;
  border-radius: 3px;
  height: 6px;
  width: 72px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%)
}

.process__item:nth-child(1) {
  background-image: url(../img/icon-1.svg)
}

.process__item:nth-child(2) {
  background-image: url(../img/icon-2.svg)
}

.process__item:nth-child(3) {
  background-image: url(../img/icon-3.svg)
}

.process__item:nth-child(4) {
  background-image: url(../img/icon-4.svg)
}

.process__item:nth-child(5) {
  background-image: url(../img/icon-5.svg)
}

.process__item:nth-child(6) {
  background-image: url(../img/icon-6.svg)
}

.process__item:nth-child(7) {
  background-image: url(../img/icon-7.svg)
}

.process__item:nth-child(8) {
  background-image: url(../img/icon-8.svg)
}

.hiring {
  padding-bottom: 51px;
  text-align: center;
  background: url(../img/models.png) no-repeat center top, url(../img/circles.svg) no-repeat calc(50% - 720px) calc(100% - 80px)
}

.hiring .h2 {
  padding-top: 110px;
  color: #fff;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 20px;
  text-transform: capitalize
}

.hiring .h2::after {
  content: '';
  position: absolute;
  background-color: #ffc807;
  border-radius: 3px;
  height: 6px;
  width: 72px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%)
}

.hiring__description {
  width: 975px;
  margin: 0 auto;
  color: #fff;
  font-weight: 600;
  margin-bottom: 155px
}

.hiring__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 80px
}

.hiring__item {
  background: #fff;
  -webkit-box-shadow: 0 30px 50px rgba(54, 69, 114, .08);
  box-shadow: 0 30px 50px rgba(54, 69, 114, .08);
  border-radius: 12px;
  padding: 0 30px 30px 30px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.hiring__item::after {
  content: '';
  position: absolute;
  top: -130px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 294px;
  height: 294px;
  background: url(../img/team.png) no-repeat;
  background-size: contain
}

.hiring__item .h3 {
  padding-top: 169px;
  font-size: 32px;
  line-height: normal;
  margin-bottom: 22px
}

.hiring__item:nth-child(2)::after {
  background: url(../img/cash.png) no-repeat;
  background-size: contain
}

.hiring__item:last-child::after {
  top: -110px;
  background: url(../img/clock.png) no-repeat;
  background-size: contain
}

.hiring__item-text {
  width: 427px;
  margin: 0 auto 30px auto
}

.hiring__item-points {
  text-align: left;
  margin-top: auto
}

.hiring__item-point {
  font-weight: 600;
  padding: 15px 0;
  background: url(../img/check.svg) no-repeat right center;
  border-top: 1px solid rgba(196, 196, 196, .2)
}

.hiring__item-point:last-child {
  padding-bottom: 0
}

.contacts {
  background-color: #203b88
}

.contacts .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 28px 0
}

.contacts .logo {
  margin-right: 65px
}

.contacts__social {
  margin-left: auto
}

.contacts__social-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 14px
}

.contacts__social svg:hover path {
  fill: #997804
}

.contacts__social svg:hover circle {
  fill: #ffc807;
  opacity: 1;
  fill-opacity: 1;
  stroke: #c9841a
}

.contacts__address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.contacts__address-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 46px
}

.contacts__address strong {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 29px;
  padding-left: 30px;
  font-family: Hind, Arial, sans-serif;
  background: url(../img/location.svg) no-repeat left center;
  background-size: 22px
}

.contacts__address-wrap:nth-child(2) strong {
  background: url(../img/email.svg) no-repeat left center
}

.contacts__address-email,
.contacts__address-location {
  color: #fff;
  font-size: 14px;
  line-height: 150%;
  padding-left: 20px;
  margin-left: 10px;
  position: relative
}

.contacts__address-email::after,
.contacts__address-location::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #ffc807;
  height: 14px;
  width: 2px
}

.contacts__address-location::after {
  height: 33px
}

.contacts__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 100px;
}

.contacts__links a {
  color: #fff;
  font-size: 16px;
  line-height: 21px;
  margin: 0 15px;
  -webkit-transition: color .3s;
  -o-transition: color .3s;
  transition: color .3s
}

.contacts__links a+a {
  position: relative;
}

.contacts__links a+a:before {
  content: '';
  position: absolute;
  top: 0;
  left: -15px;
  height: 100%;
  width: 2px;
  background-color: #FFC807;
}

.contacts__links a:hover {
  color: #ffc807
}

.privacy {
  width: 1440px;
  margin: -40px auto 40px auto;
  color: #191a1a
}

.page-title {
  color: #fff;
  font-size: 50px;
  line-height: normal;
  text-align: center;
  margin: 80px 0
}

.box-info {
  padding: 30px;
  background-color: #fff;
  border-radius: 10px
}

.box-info h2 {
  margin-bottom: 15px;
  color: #191a1a
}

.box-info p {
  margin-bottom: 30px;
  line-height: 1.5;
  color: #191a1a
}

.box-info ul {
  padding-left: 20px;
  margin-bottom: 30px;
  list-style: disc
}

.box-info li {
  margin-bottom: 10px;
  line-height: 1.5
}

.box-info a {
  word-wrap: break-word
}

.footer {
  background-color: #1a2f6d;
  padding-top: 18px;
  padding-bottom: 17px
}

.footer .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.footer-copyright {
  color: #fff;
  font-size: 14px;
  line-height: 150%;
  padding-left: 16px;
  position: relative;
  margin-right: auto;
  font-weight: 600
}

.footer-copyright::before {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  background-color: #ffc807;
  width: 6px;
  height: 6px;
  border-radius: 50%
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 89, 245, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  display: none;
}

.popup.shown {
  display: flex;
}

.popup__block {
  border-radius: 32px;
  background-color: #fff;
  padding: 32px;
  text-align: center;

  position: relative;
}

.popup__close {
  position: absolute;
  width: 16px;
  height: 16px;
  bottom: 100%;
  left: 100%;
  cursor: pointer;
}


.popup__title {
  font-size: 46px;
  color: #364572;
  font-weight: 800;
  font-family: "Nunito", sans-serif;
  line-height: 1;
}

.popup__text {
  font-size: 18px;
  color: #364572;
  font-weight: 400;
  font-family: "Nunito Sans", sans-serif;
  margin-top: 20px;
  line-height: 1;
}