html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

::-webkit-input-placeholder {
  color: #1a1a1a66;
}

::placeholder {
  color: #1a1a1a66;
}

:-ms-input-placeholder {
  color: #1a1a1a66;
}

::placeholder {
  color: #1a1a1a66;
}

::placeholder {
  color: #1a1a1a66;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

strong {
  font-weight: 700;
}

body {
  color: #687086;
  background-color: #2058fa;
  font-family: Open Sans, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

body:before {
  content: " ";
  will-change: transform;
  z-index: -1;
  background: url("main-bg.487a57eb.png") 0 0 / cover no-repeat;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.bg {
  background: url("circle.5ff90812.png") calc(50% + 30px) 10px / 1920px no-repeat;
  position: relative;
  overflow-x: hidden;
}

.bg:after {
  content: "";
  background: url("circles.90d803ba.svg") 100% 0 no-repeat;
  width: 345px;
  height: 275px;
  position: absolute;
  top: 48%;
  left: calc(50% + 730px);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.container {
  max-width: 1650px;
  margin: 0 auto;
  padding: 0 10px;
}

.logo {
  width: 277px;
}

.logo img {
  width: 277px;
  margin: 0 auto;
  display: block;
}

.h1 {
  color: #fff;
  font-family: Hind, Arial, sans-serif;
  font-size: 72px;
  font-weight: 700;
  line-height: 80px;
}

.h2 {
  font-family: Hind, Arial, sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
}

.h3 {
  font-family: Hind, Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.icon {
  color: #fff;
  font-family: Hind, Arial, sans-serif;
  font-size: 16px;
  line-height: 16px;
}

.uppercase {
  text-transform: uppercase;
  font-family: Raleway, Arial, sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 13px;
}

.marked {
  color: #18daab;
}

.button {
  color: #0009;
  cursor: pointer;
  -o-transition: opacity .3s;
  -o-transition: opacity .3s;
  background: -o-linear-gradient(357.13deg, #eda130 0, #ffc107 100%);
  background: linear-gradient(92.87deg, #eda130 0, #ffc107 100%);
  border: none;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  padding: 18px 48px 17px 24px;
  font-family: Raleway, Arial, sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 21px;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  position: relative;
  -webkit-box-shadow: 0 2px #c7821a, 0 24px 50px #eda13070;
  box-shadow: 0 2px #c7821a, 0 24px 50px #eda13070;
}

.button:hover {
  opacity: .9;
}

.button:after {
  content: "";
  background-color: #c0900b;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 56px;
}

.button--pen {
  background: url("pen.dd87c81d.svg") calc(100% - 18px) no-repeat, -o-linear-gradient(357.13deg, #eda130 0, #ffc107 100%);
  background: url("pen.dd87c81d.svg") calc(100% - 18px) no-repeat, -o-linear-gradient(357.13deg, #eda130 0, #ffc107 100%);
  background: url("pen.dd87c81d.svg") calc(100% - 18px) no-repeat, linear-gradient(92.87deg, #eda130 0, #ffc107 100%);
  padding-right: 82px;
}

.button--sign-up {
  background: url("sign-up.1dc5e293.svg") calc(100% - 18px) no-repeat, -o-linear-gradient(357.13deg, #eda130 0, #ffc107 100%);
  background: url("sign-up.1dc5e293.svg") calc(100% - 18px) no-repeat, -o-linear-gradient(357.13deg, #eda130 0, #ffc107 100%);
  background: url("sign-up.1dc5e293.svg") calc(100% - 18px) no-repeat, linear-gradient(92.87deg, #eda130 0, #ffc107 100%);
  padding-left: 40px;
}

.label {
  color: #16113d;
  margin-bottom: 6px;
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  line-height: 15px;
}

.required {
  color: #e31a3e;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
}

.input {
  color: #1a1a1a;
  background-color: #fff;
  border: 1px solid #949494;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  outline: 0;
  width: 100%;
  min-height: 40px;
  padding: 10px 12px;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  line-height: 17px;
}

.input:focus {
  border-color: #8e85cd;
}

.select {
  color: #1a1a1a;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #949494;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  outline: 0;
  width: 100%;
  min-height: 40px;
  padding: 10px 12px;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  line-height: 17px;
}

.submit {
  color: #fff;
  background: -webkit-gradient(linear, left bottom, left top, from(#2058fa), to(#2058fa));
  background: -o-linear-gradient(#2058fa, #2058fa);
  background: linear-gradient(0deg, #2058fa, #2058fa);
  -webkit-border-radius: 100px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 221px;
  height: 40px;
  padding: 0;
  font-family: Hind, Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  -webkit-box-shadow: 0 12px 20px #2058fa33;
  box-shadow: 0 12px 20px #2058fa33;
}

.submit:after {
  display: none;
}

.header {
  margin-bottom: 155px;
  padding-top: 34px;
}

.header-top {
  grid-template-columns: 277px 1fr 277px;
  align-items: center;
  display: grid;
}

.header .logo {
  width: 277px;
}

.header-nav {
  justify-self: center;
}

.header-nav__list {
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.header-nav__item {
  margin-right: 50px;
}

.header-nav__link {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 150%;
  display: block;
  position: relative;
}

.header-nav__link:hover:after {
  content: "";
  background-color: #ffc807;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  width: 100%;
  height: 6px;
  position: absolute;
  bottom: -6px;
  left: 0;
}

.preview {
  padding-bottom: 101px;
}

.preview .container {
  justify-content: space-between;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.preview .h1 {
  margin-bottom: 40px;
  padding-left: 36px;
  position: relative;
}

.preview .h1:after {
  content: "";
  background-color: #ffc807;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  width: 6px;
  height: 90%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.preview__text {
  width: 864px;
}

.preview__list {
  grid-template-columns: 1fr 1fr;
  gap: 21px 0;
  width: 640px;
  margin-bottom: 40px;
  display: grid;
}

.preview .button--pen {
  margin-bottom: 45px;
}

.preview__item {
  color: #fff;
  background: url("check.05a0dd5e.svg") 0 / 20px no-repeat;
  padding-left: 30px;
  font-weight: 600;
}

.preview__advatages {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.preview__advantage {
  color: #fff;
  background-image: url("20+.d8d53d95.png");
  background-position: center;
  background-repeat: no-repeat;
  min-height: 120px;
  padding: 55px 15px 16px 22px;
  font-weight: 600;
}

.preview__advantage:first-child {
  background-size: 296px;
  width: 303px;
  margin-right: 35px;
}

.preview__advantage:last-child {
  background-image: url("99.e068a14b.png");
  background-size: 220px;
  width: 220px;
}

.preview .form {
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#edeff7));
  background: -o-linear-gradient(top, #fff 0, #edeff7 100%);
  background: linear-gradient(#fff 0, #edeff7 100%);
  -webkit-border-radius: 20px;
  border-radius: 20px;
  align-self: flex-start;
  width: 390px;
  padding: 30px 24px 26px;
  -webkit-transform: translate(-100px, 30px);
  -ms-transform: translate(-100px, 30px);
  -o-transform: translate(-100px, 30px);
  transform: translate(-100px, 30px);
  -webkit-box-shadow: 0 4px 46px #00000040;
  box-shadow: 0 4px 46px #00000040;
}

.preview .form-title {
  color: #364572;
  text-align: center;
  margin-bottom: 24px;
  padding-bottom: 13px;
  font-family: Hind, Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.preview .form-title:after {
  content: "";
  background-color: #ffc807;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  width: 72px;
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.preview .form-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  margin: 0 auto 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.preview .form-row--flex {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.preview .form-row__item--small {
  width: 100%;
  max-width: 100px;
  position: relative;
}

.form-row__item--small:before {
  content: "";
  pointer-events: none;
  background-image: url("select-arrow.210abcfc.png");
  background-size: contain;
  width: 16px;
  height: 9px;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.preview .form-footer {
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.services .container {
  background: #fff;
  -webkit-border-radius: 24px;
  border-radius: 24px;
  padding: 60px;
  -webkit-box-shadow: 0 30px 60px #0000001a;
  box-shadow: 0 30px 60px #0000001a;
}

.services .h2 {
  text-align: center;
  margin-bottom: 66px;
  padding-bottom: 15px;
  position: relative;
}

.services .h2:after {
  content: "";
  background-color: #ffc807;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  width: 72px;
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.services__list {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  display: grid;
}

.services__item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #36457233;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  min-height: 308px;
  padding: 30px;
  -webkit-box-shadow: 0 30px 50px #36457214;
  box-shadow: 0 30px 50px #36457214;
}

.services__item .h3 {
  background-position: 0;
  background-repeat: no-repeat;
  align-items: center;
  min-height: 80px;
  margin-bottom: 32px;
  padding-left: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.services__item-point {
  background-position: 0;
  background-repeat: no-repeat;
  margin-bottom: 14px;
  padding-left: 32px;
}

.services .services__item-point:first-of-type {
  background-image: url("app-store.c0701d7d.svg");
}

.services .services__item-point:nth-of-type(2) {
  background-image: url("games.e2f2709e.svg");
}

.services .services__item-point:nth-of-type(3) {
  background-image: url("ux.762f34b2.svg");
}

.services .services__item-point:nth-of-type(4) {
  background-image: url("tablet.9ea34019.svg");
}

.services__item:first-child .h3 {
  background-image: url("iphone.47e6bd17.svg");
}

.services__item:nth-child(2) .h3 {
  background-image: url("ipad.107ac203.svg");
}

.services__item:nth-child(3) .h3 {
  background-image: url("android.45e37062.svg");
}

.services__item:last-child {
  background-color: #2058fa;
}

.services__item:last-child .h3 {
  color: #fff;
  background-image: url("idea.b9aa0aaf.svg");
}

.services__item:last-child p {
  color: #fff;
  margin-bottom: 30px;
}

.development {
  background: url("development.af92b1db.png") top / 1735px no-repeat;
  margin-top: -20px;
  margin-bottom: 54px;
}

.development .h2 {
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 60px;
  padding-top: 100px;
  padding-bottom: 15px;
  position: relative;
}

.development .h2:after {
  content: "";
  background-color: #ffc807;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  width: 72px;
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.development .wrap {
  justify-content: space-between;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.development__left {
  width: 670px;
}

.development__left p {
  color: #fff;
  margin-bottom: 20px;
}

.development__left .button {
  margin-top: 40px;
}

.development__list {
  grid-template-columns: repeat(5, 1fr);
  gap: 30px 58px;
  display: grid;
}

.development__item {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.development__item img {
  margin-bottom: 15px;
}

.process {
  background: url("process.85431e6a.png") top no-repeat;
  margin-bottom: 41px;
  position: relative;
}

.process:after {
  content: "";
  z-index: -1;
  background-color: #ffc807;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 373px;
  height: 373px;
  position: absolute;
  top: 230px;
  left: calc(50% - 950px);
}

.process:before {
  content: "";
  z-index: -1;
  background-color: #ffc807;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 119px;
  height: 119px;
  position: absolute;
  top: 1004px;
  left: calc(50% + 750px);
}

.process .h2 {
  text-align: center;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 30px;
  padding-top: 100px;
  padding-bottom: 15px;
  position: relative;
}

.process .h2:after {
  content: "";
  background-color: #ffc807;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  width: 72px;
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.process__tabs {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #fff;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  width: 730px;
  min-height: 66px;
  margin: 0 auto 85px;
  display: grid;
}

.process .active {
  color: #0009;
  background: -o-linear-gradient(357.13deg, #eda130 0, #ffc107 100%);
  background: linear-gradient(92.87deg, #eda130 0, #ffc107 100%);
  -webkit-border-radius: 100px;
  border-radius: 100px;
  padding: 21px 49px;
}

.process__tab {
  color: #fff;
}

.process__list {
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  display: grid;
}

.process__item {
  text-align: center;
  background: #fff 50% 30px no-repeat;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  min-height: 343px;
  padding: 0 20px;
  -webkit-box-shadow: 0 20px 50px #111d424d;
  box-shadow: 0 20px 50px #111d424d;
}

.process__item .h3 {
  margin-bottom: 30px;
  padding-top: 130px;
  padding-bottom: 20px;
  position: relative;
}

.process__item .h3:after {
  content: "";
  background-color: #ffc807;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  width: 72px;
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.process__item:first-child {
  background-image: url("icon-1.c03dd1cd.svg");
}

.process__item:nth-child(2) {
  background-image: url("icon-2.2b23097f.svg");
}

.process__item:nth-child(3) {
  background-image: url("icon-3.949244e7.svg");
}

.process__item:nth-child(4) {
  background-image: url("icon-4.efcc3a82.svg");
}

.process__item:nth-child(5) {
  background-image: url("icon-5.68e01f84.svg");
}

.process__item:nth-child(6) {
  background-image: url("icon-6.c7e1d9a1.svg");
}

.process__item:nth-child(7) {
  background-image: url("icon-7.c4536270.svg");
}

.process__item:nth-child(8) {
  background-image: url("icon-8.a9650f6d.svg");
}

.hiring {
  text-align: center;
  background: url("models.8b4a9396.png") top no-repeat, url("circles.90d803ba.svg") calc(50% - 720px) calc(100% - 80px) no-repeat;
  padding-bottom: 51px;
}

.hiring .h2 {
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 20px;
  padding-top: 110px;
  padding-bottom: 15px;
  position: relative;
}

.hiring .h2:after {
  content: "";
  background-color: #ffc807;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  width: 72px;
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.hiring__description {
  color: #fff;
  width: 975px;
  margin: 0 auto 155px;
  font-weight: 600;
}

.hiring__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 80px;
  display: grid;
}

.hiring__item {
  background: #fff;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  padding: 0 30px 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-box-shadow: 0 30px 50px #36457214;
  box-shadow: 0 30px 50px #36457214;
}

.hiring__item:after {
  content: "";
  background: url("team.6934ba28.png") 0 0 / contain no-repeat;
  width: 294px;
  height: 294px;
  position: absolute;
  top: -130px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.hiring__item .h3 {
  margin-bottom: 22px;
  padding-top: 169px;
  font-size: 32px;
  line-height: normal;
}

.hiring__item:nth-child(2):after {
  background: url("cash.4668b495.png") 0 0 / contain no-repeat;
}

.hiring__item:last-child:after {
  background: url("clock.d589e093.png") 0 0 / contain no-repeat;
  top: -110px;
}

.hiring__item-text {
  width: 427px;
  margin: 0 auto 30px;
}

.hiring__item-points {
  text-align: left;
  margin-top: auto;
}

.hiring__item-point {
  background: url("check.05a0dd5e.svg") 100% no-repeat;
  border-top: 1px solid #c4c4c433;
  padding: 15px 0;
  font-weight: 600;
}

.hiring__item-point:last-child {
  padding-bottom: 0;
}

.contacts {
  background-color: #203b88;
}

.contacts .container {
  align-items: center;
  padding: 28px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.contacts .logo {
  margin-right: 65px;
}

.contacts__social {
  margin-left: auto;
}

.contacts__social-list {
  grid-template-columns: repeat(4, 1fr);
  gap: 14px;
  display: grid;
}

.contacts__social svg:hover path {
  fill: #997804;
}

.contacts__social svg:hover circle {
  fill: #ffc807;
  opacity: 1;
  fill-opacity: 1;
  stroke: #c9841a;
}

.contacts__address {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.contacts__address-wrap {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  margin-right: 46px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.contacts__address strong {
  color: #fff;
  background: url("location.d0d6b899.svg") 0 / 22px no-repeat;
  padding-left: 30px;
  font-family: Hind, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
}

.contacts__address-wrap:nth-child(2) strong {
  background: url("email.ed39b4c4.svg") 0 no-repeat;
}

.contacts__address-email, .contacts__address-location {
  color: #fff;
  margin-left: 10px;
  padding-left: 20px;
  font-size: 14px;
  line-height: 150%;
  position: relative;
}

.contacts__address-email:after, .contacts__address-location:after {
  content: "";
  background-color: #ffc807;
  width: 2px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.contacts__address-location:after {
  height: 33px;
}

.contacts__links {
  margin-left: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.contacts__links a {
  color: #fff;
  -o-transition: color .3s;
  -o-transition: color .3s;
  margin: 0 15px;
  font-size: 16px;
  line-height: 21px;
  -webkit-transition: color .3s;
  transition: color .3s;
}

.contacts__links a + a {
  position: relative;
}

.contacts__links a + a:before {
  content: "";
  background-color: #ffc807;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -15px;
}

.contacts__links a:hover {
  color: #ffc807;
}

.privacy {
  color: #191a1a;
  width: 1440px;
  margin: -40px auto 40px;
}

.page-title {
  color: #fff;
  text-align: center;
  margin: 80px 0;
  font-size: 50px;
  line-height: normal;
}

.box-info {
  background-color: #fff;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 30px;
}

.box-info h2 {
  color: #191a1a;
  margin-bottom: 15px;
}

.box-info p {
  color: #191a1a;
  margin-bottom: 30px;
  line-height: 1.5;
}

.box-info ul {
  margin-bottom: 30px;
  padding-left: 20px;
  list-style: disc;
}

.box-info li {
  margin-bottom: 10px;
  line-height: 1.5;
}

.box-info a {
  word-wrap: break-word;
}

.footer {
  background-color: #1a2f6d;
  padding-top: 18px;
  padding-bottom: 17px;
}

.footer .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.footer-copyright {
  color: #fff;
  margin-right: auto;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  position: relative;
}

.footer-copyright:before {
  content: "";
  background-color: #ffc807;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.popup {
  background-color: #2359f5e6;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.popup.shown {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.popup__block {
  text-align: center;
  background-color: #fff;
  -webkit-border-radius: 32px;
  border-radius: 32px;
  padding: 32px;
  position: relative;
}

.popup__close {
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 100%;
  left: 100%;
}

.popup__title {
  color: #364572;
  font-family: Nunito, sans-serif;
  font-size: 46px;
  font-weight: 800;
  line-height: 1;
}

.popup__text {
  color: #364572;
  margin-top: 20px;
  font-family: Nunito Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
}
/*# sourceMappingURL=index.4e60908a.css.map */
